import React, {useContext} from 'react'
import tw, {styled} from 'twin.macro'
import {ModalContext} from '../context/ModalContext'
const containerVariants = {
  // Named class sets
  light: tw`bg-white text-primary`,
  dark: tw`bg-primary text-white`,
}

export default ({children, href, variant = 'dark', ...rest}) => {
  const {Modal_onOpen} = useContext(ModalContext)

  const Button = styled.button(() => [
    // Return a function here
    tw`py-3 px-6 text-2xl rounded inline-flex uppercase font-bold shadow-xl hover:shadow-none transition duration-700 ease-in-out cursor-pointer hover:cursor-pointer`,
    containerVariants[variant], // Grab the variant style via a prop
  ])

  return (
    <>
      <Button as="a" onClick={Modal_onOpen} {...rest}>
        {children}
      </Button>
    </>
  )
}
