import React from 'react'
import tw, {css} from 'twin.macro'
import {Logo, MainMenu, Container, Row, Column} from './../components'

export default () => {
  return (
    <footer tw="py-10 bg-light">
      <Container>
        <Row tw="items-center">
          <Column w="1/4">
            <div tw="w-48 mx-auto lg:ml-0">
              <Logo />
            </div>
          </Column>
          <Column w="3/4">
            <MainMenu
              variant="footer"
              tw="hidden lg:flex"
              isScrolled
              isOpen
            ></MainMenu>
          </Column>
        </Row>
        <Row tw="my-10">
          <p tw="text-xs text-gray-600">
            This franchising information is not intended as an offer to sell, or
            the solicitation of an offer to buy, a franchise. It is for
            information purposes only. The following states regulate the offer
            and sale of franchises. These states are California, Hawaii,
            Illinois, Indiana, Maryland, Michigan, Minnesota, New York, North
            Dakota, Oregon, Rhode Island, South Dakota, Virginia, Washington and
            Wisconsin. If you are a resident of one of these states or intend to
            operate a franchise in any of these states, we will not offer or
            sell you a franchise unless and until we have complied with any
            applicable pre-sale registration and/or disclosure requirements in
            your state. This offering of a franchise. In New York can only be
            made by a prospectus that has been previously filed and registered
            with the Department of Law of the State of New York. The application
            for registration of an offering prospectus or the acceptance and
            filing by the Department of Law as required by the State of New York
            law does not constitute NY approval of the offering or the sale of
            such franchise by the Department of Law or the attorney general of
            New York. The California Department of Corporations has not reviewed
            or approved our website.
          </p>
        </Row>
      </Container>
    </footer>
  )
}
