import React, {useState} from 'react'
import {Logo, MainMenu, Container, Row, Column} from './../components'
import tw, {css} from 'twin.macro'
import {m, useViewportScroll} from 'framer-motion'
import {Squash as Hamburger} from 'hamburger-react'
export default () => {
  const [isScrolled, setIsScrolled] = useState(false)
  const [isOpen, setOpen] = useState(false)

  const {scrollY} = useViewportScroll()
  scrollY.onChange((y) => {
    setIsScrolled(() => y > 50)
  })

  const transition = {duration: 0.5, ease: 'easeInOut'}
  const variants = {
    scrolled: {opacity: 1, backgroundColor: 'white', transition},
    top: {opacity: 1, backgroundColor: 'transparent', transition},
  }

  return (
    <m.header
      css={[
        tw`bg-white lg:bg-transparent fixed w-full z-30 py-2 lg:py-6 transition duration-500 ease-in-out`,
        isScrolled && tw`bg-white lg:bg-white lg:py-2 shadow-xl`,
      ]}
    >
      <Container>
        <Row tw="items-center">
          <Column w="1/4" tw="flex items-center justify-between">
            <div tw="w-32 lg:w-48">
              <Logo />
            </div>
            <div tw="lg:hidden">
              <Hamburger toggled={isOpen} toggle={setOpen} />
            </div>
          </Column>
          <Column w="3/4" css={[tw`hidden lg:block`, isOpen && tw`block`]}>
            <MainMenu isScrolled={isScrolled} isOpen={isOpen}></MainMenu>
          </Column>
        </Row>
      </Container>
    </m.header>
  )
}
