import React from 'react'
import tw, {styled} from 'twin.macro'
import {Column, Row, Container, Button} from '../components'
import image from '../images/blob-dog.png'
import van from '../images/Van-DriverSide.png'
import {m} from 'framer-motion'
import Arrow from '../svg/right.svg'

import Icon1 from '../svg/bottom/one.svg'
import Icon2 from '../svg/bottom/two.svg'
import Icon3 from '../svg/bottom/three.svg'
import Icon4 from '../svg/bottom/four.svg'
import Icon5 from '../svg/bottom/five.svg'
import Icon6 from '../svg/bottom/six.svg'
import Icon7 from '../svg/bottom/seven.svg'

const list = [
  {title: 'Branding', icon: <Icon1 />},
  {title: 'Training', icon: <Icon2 />},
  {title: 'Direct Mail', icon: <Icon3 />},
  {title: 'Online Search Marketing', icon: <Icon4 />},
  {title: 'Community Outreach', icon: <Icon5 />},
  {title: 'Referral Networking', icon: <Icon6 />},
  {title: 'Proven Customer Retention Programs', icon: <Icon7 />},
]

const container = {
  hidden: {opacity: 0},
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
}

const item = {
  hidden: {opacity: 0},
  show: {opacity: 1},
}

const Title = ({children, ...rest}) => {
  return (
    <h2 tw="font-bold text-6xl text-primary" {...rest}>
      {children}
    </h2>
  )
}
export default () => {
  return (
    <div tw="relative bg-light">
      <section>
        <Container tw="text-center">
          <div tw="max-w-screen-sm mx-auto">
            <h3 tw="font-bold text-4xl text-primary mb-6">
              We’ll help you make a splash.
            </h3>
            <p>
              Whether you want to start a grooming business, convert yours to a
              Zoomin Groomin franchise, or even if you’ve never groomed a pet in
              your life, we'll help you succeed with marketing, training and
              more! When you succeed, we succeed.
            </p>
          </div>
          <m.ul
            variants={container}
            initial="hidden"
            animate="show"
            tw="flex flex-wrap lg:flex-nowrap text-center items-start justify-between py-10"
          >
            {list.map((e, i) => (
              <m.li
                key={i}
                variants={item}
                tw="mx-auto lg:flex-1 text-secondary flex flex-col p-2 w-full md:w-1/3"
              >
                <span tw="w-16 h-16 flex items-center justify-center m-auto">
                  {e.icon}
                </span>
                <span tw="my-2 text-lg">{e.title}</span>
              </m.li>
            ))}
          </m.ul>
        </Container>
      </section>
      <section tw="pt-10">
        <img src={van} tw="mx-auto -mb-20" />
        <div tw="bg-primary text-white pt-32 pb-10">
          <Container>
            <Row tw="justify-center items-center leading-normal ">
              <div tw="font-bold text-2xl mb-4 lg:mb-0 lg:text-4xl  text-white">
                Learn more about becoming a franchisee today!
              </div>
              <Button variant="light" tw="mx-8 flex items-center">
                <span>Get Started</span>
                <span tw="mx-4 inline-block h-full w-6 ">
                  <Arrow />
                </span>
              </Button>
            </Row>
          </Container>
        </div>
      </section>
    </div>
  )
}
