import React, {useContext} from 'react'
import tw, {css} from 'twin.macro'
import {m} from 'framer-motion'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useDisclosure, Button} from '@chakra-ui/react'
import Arrow from '../svg/right.svg'
import {
  faFacebook,
  faInstagram,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons'
import {faEnvelope} from '@fortawesome/pro-light-svg-icons'
import {ModalContext} from '../context/ModalContext'

const menu = [
  // {title: 'About', link: '#'},
  // {title: 'Services', link: ''},
  // {title: 'Appointments', link: ''},
  // {title: 'Blog', link: ''},
  // {title: 'Franchise', link: ''},
]

const container = {
  hidden: {opacity: 0},
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
}

const item = {
  hidden: {opacity: 0},
  show: {opacity: 1},
}

export default ({variant, isScrolled, isOpen, ...rest}) => {
  const {Modal_onOpen} = useContext(ModalContext)

  return (
    <>
      <m.ul
        variants={container}
        initial="hidden"
        animate="show"
        css={[
          tw`flex flex-wrap flex-col lg:flex-row lg:flex-nowrap  items-center justify-end text-lg`,
          isScrolled && variant != 'footer' && tw`text-primary`,
          isOpen && variant != 'footer' && tw`text-primary py-6`,
        ]}
        {...rest}
      >
        {menu.map((e, i) => (
          <m.li key={i} variants={item}>
            <a href={e.link} tw="p-2 mb-2 inline-flex">
              {e.title}
            </a>
          </m.li>
        ))}
        {variant != 'footer' && (
          <m.li variants={item}>
            <a
              onClick={Modal_onOpen}
              css={[
                tw`bg-white text-primary py-2 px-6 shadow-xl hover:shadow-none transition duration-700 ease-in-out cursor-pointer hover:cursor-pointer rounded capitalize font-light text-lg inline-block mx-6 `,
                (isScrolled || isOpen) && tw`bg-primary text-white`,
                isOpen && tw`mb-4`,
              ]}
            >
              <div variant="light" tw="mx-2 flex items-center">
                <span>Contact Us</span>
                <span tw="ml-4 inline-block h-full w-6 ">
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
              </div>
            </a>
          </m.li>
        )}
        {/* {variant === 'footer' && (
          <m.li variants={item}>
            <a tw="p-2 mb-2 inline-flex" onClick={Modal_onOpen}>
              Contact Us
            </a>
          </m.li>
        )} */}
        {/* <m.li
          variants={item}
          css={[
            tw`flex justify-between text-3xl text-white`,
            (isScrolled || isOpen) && tw`text-primary`,
          ]}
        >
          <a href="" tw="px-2">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a href="" tw="px-2">
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a href="" tw="px-2">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </m.li> */}
      </m.ul>
    </>
  )
}
