import React, {useContext} from 'react'
import '../css/form.css'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import HubspotForm from 'react-hubspot-form'
import {ModalContext} from '../context/ModalContext'

const Form = ({children, ...rest}) => {
  const {Modal_isOpen, Modal_onClose} = useContext(ModalContext)
  return (
    <Modal isOpen={Modal_isOpen} onClose={Modal_onClose} size="3xl" id="foo">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <HubspotForm
            portalId="5976171"
            formId="5549abdc-dab5-4489-9137-e8d45187ec2c"
            onSubmit={() => console.log('Submit!')}
            onReady={(form) => console.log('Form ready!')}
            loading={<div>Loading...</div>}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default Form
