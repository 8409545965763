import React, {createContext} from 'react'
import {useDisclosure} from '@chakra-ui/react'
export const ModalContext = createContext()

export function ModalProvider(props) {
  const {
    isOpen: Modal_isOpen,
    onOpen: Modal_onOpen,
    onClose: Modal_onClose,
  } = useDisclosure()
  return (
    <ModalContext.Provider value={{Modal_isOpen, Modal_onOpen, Modal_onClose}}>
      {props.children}
    </ModalContext.Provider>
  )
}
