import React from 'react'
import tw, {styled} from 'twin.macro'
import MainLogo from '../svg/logo.svg'

export default ({children, href}) => {
  return (
    <>
      <MainLogo />
    </>
  )
}
