import React from 'react'
import tw, {styled} from 'twin.macro'
import {Column, Row, Container, Button} from '../components'
import blobImage from '../images/everything.png'
import van from '../images/Van-Angled-Person-Arrow.png'
import {m} from 'framer-motion'
import Arrow from '../svg/right.svg'

import Icon1 from '../svg/one.svg'
import Icon2 from '../svg/two.svg'
import Icon3 from '../svg/three.svg'
import Icon4 from '../svg/four.svg'
import Icon5 from '../svg/five.svg'

const list = [
  {title: 'Profitable,Growing Industry', icon: <Icon1 />},
  {title: 'Home-Based Business', icon: <Icon2 />},
  {title: 'Flexible Lifestyle', icon: <Icon3 />},
  {title: 'ComprehensiveTraining', icon: <Icon4 />},
  {title: 'Branding &Marketing Support', icon: <Icon5 />},
]

const container = {
  hidden: {opacity: 0},
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
}

const item = {
  hidden: {opacity: 0},
  show: {opacity: 1},
}

const Title = ({children, ...rest}) => {
  return (
    <h2 tw="font-bold text-4xl lg:text-6xl text-primary mb-2" {...rest}>
      {children}
    </h2>
  )
}
const Intro = () => {
  return (
    <div tw="relative">
      <img
        src={blobImage}
        tw="object-cover lg:absolute transform translate-y-0 md:-translate-y-12 lg:translate-y-0  m-auto right-0  lg:mt-auto"
      />
      <section
        tw="flex items-center justify-center"
        style={{maxHeight: '1400px'}}
      >
        <Container tw=" lg:mt-12 relative lg:py-48">
          <Row tw="justify-between">
            <Column w="1/3">
              <div>
                <Title>Start your own franchise!</Title>
                <p>
                  Sniffing around for a great new opportunity? Zoomin Groomin is
                  expanding our mobile grooming franchises nationwide. Set your
                  own schedule, be your own boss and work with pets all day.
                  We’ll be right by your side, every step of the way. Woof!
                </p>
                <Button tw="my-6 flex items-center">
                  <span>Get Started</span>
                  <span tw="mx-4 inline-block h-full w-6 ">
                    <Arrow />
                  </span>
                </Button>
              </div>
            </Column>
          </Row>
        </Container>
      </section>
      <section tw="relative">
        <Container tw="text-center">
          <h2 tw="font-bold text-primary my-8 text-4xl">
            Why choose Zoomin Groomin?
          </h2>
          <m.ul
            variants={container}
            initial="hidden"
            animate="show"
            tw="flex flex-wrap lg:flex-nowrap text-center items-start justify-between py-10"
          >
            {list.map((e, i) => (
              <m.li
                key={i}
                variants={item}
                tw="mx-auto lg:flex-1 text-secondary flex flex-col p-2 w-full md:w-1/3"
              >
                <span tw="w-16 h-16 flex items-center justify-center m-auto">
                  {e.icon}
                </span>
                <span tw="my-2 text-lg">{e.title}</span>
              </m.li>
            ))}
          </m.ul>
        </Container>
      </section>
    </div>
  )
}

export default Intro
