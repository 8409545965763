import * as React from 'react'
import tw, {css} from 'twin.macro'
const Column = ({children, w, ...rest}) => {
  const variants = {
    '3/4': tw`w-full lg:w-3/4`,
    '1/4': tw`w-full lg:w-1/4`,
    '1/3': tw`w-full lg:w-1/3`,
    '2/3': tw`w-full lg:w-2/3`,
    '1/2': tw`w-full lg:w-1/2`,
    1: tw`w-full`,
  }

  const styles = {
    column: ({w = '1/2'}) => [variants[w]],
  }

  return (
    <div css={styles.column({w})} {...rest}>
      {children}
    </div>
  )
}

export default Column
