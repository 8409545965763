import React, {useRef} from 'react'
import tw, {styled} from 'twin.macro'
import {GatsbyImage} from 'gatsby-plugin-image'
import {
  motion,
  useViewportScroll,
  useTransform,
  useElementScroll,
} from 'framer-motion'
import {scrollProgress} from '../hooks'
import {useInView, InView} from 'react-intersection-observer'

const Wrapper = tw.div`
  bg-gray-500 hover:bg-gray-800 text-white p-2 rounded inline-block
`
export default ({children, title, image, ...rest}) => {
  const ref = useRef()
  const {start, end} = scrollProgress(ref)
  const {scrollYProgress} = useViewportScroll()
  const opacity = useTransform(scrollYProgress, [end - start, start], [0, 1])

  return (
    <>
      <motion.section
        ref={ref}
        tw="container mx-auto"
        style={{opacity: opacity}}
        {...rest}
      >
        <div tw="flex flex-wrap h-full">
          <div tw="w-full md:w-1/2">
            <img src={image} />
          </div>
          <div tw="w-full md:w-1/2">
            {title && <h2>{title}</h2>}
            {children || (
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                in justo nec magna volutpat ultricies. Suspendisse semper
                molestie diam, placerat cursus libero consequat id. Donec
                eleifend scelerisque odio, eget pretium nisl. Vivamus commodo
                elit id est consequat cursus. Aenean sit amet malesuada augue,
                vitae lacinia risus. Ut sit amet est id magna finibus placerat.
                Suspendisse potenti. Vestibulum lacinia sit amet libero ultrices
                auctor.
              </p>
            )}
          </div>
        </div>
      </motion.section>
    </>
  )
}
