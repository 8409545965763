import React from 'react'
import tw, {styled} from 'twin.macro'
import {Column, Row, Container, Button} from '../components'
import image from '../images/blob-dog.png'
import {m} from 'framer-motion'

import Icon1 from '../svg/bottom/one.svg'
import Icon2 from '../svg/bottom/two.svg'
import Icon3 from '../svg/bottom/three.svg'
import Icon4 from '../svg/bottom/four.svg'
import Icon5 from '../svg/bottom/five.svg'

const list = [
  {title: 'Profitable,Growing Industry', icon: <Icon1 />},
  {title: 'Home-Based Business', icon: <Icon2 />},
  {title: 'Flexible Lifestyle', icon: <Icon3 />},
  {title: 'ComprehensiveTraining', icon: <Icon4 />},
  {title: 'Branding &Marketing Support', icon: <Icon5 />},
]

const container = {
  hidden: {opacity: 0},
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
}

const item = {
  hidden: {opacity: 0},
  show: {opacity: 1},
}

const Title = ({children, ...rest}) => {
  return (
    <h2 tw="font-bold text-6xl text-primary" {...rest}>
      {children}
    </h2>
  )
}
const Results = () => {
  return (
    <div tw="relative py-32 bg-light">
      <section tw=" flex items-center justify-center bg-white">
        <Container tw="relative bg-white">
          <Row tw="justify-between items-center">
            <Column>
              <img src={image} tw="mx-auto -mt-8 -mb-8" />
            </Column>
            <Column>
              <div>
                <div tw="p-6 max-w-screen-sm">
                  <h3 tw="font-bold text-4xl text-primary my-4">
                    Proven Results for Pet Lovers
                  </h3>
                  <p>
                    Today’s pet services industry is one of the fastest-growing
                    sectors in the nation. In fact, more than two thirds of
                    American households own a pet, and with so many adopting new
                    furry friends during the pandemic, demand for mobile pet
                    grooming is higher than ever!
                  </p>
                </div>

                <Row tw="text-center">
                  <div tw="p-6 mx-auto">
                    <div tw="text-5xl text-secondary font-bold">
                      <span tw="text-2xl align-top">$</span>207.9B
                    </div>
                    Global Pet Care Market
                  </div>
                  <div tw="p-6 mx-auto">
                    <div tw="text-5xl text-secondary font-bold">
                      <span tw="text-2xl align-top">$</span>72.56B
                    </div>
                    U.S. Pet Spending in 2020
                  </div>
                  <div tw="p-6 mx-auto">
                    <div tw="text-5xl text-secondary font-bold">67%</div> US
                    Households with a pet
                  </div>
                </Row>
              </div>
            </Column>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export default Results
