import React, {useRef} from 'react'
import Layout from '../layouts'
import {Column, Row, Container, Button} from '../components'
import {Intro, Results, Marketing} from '../sections'

import tw, {styled} from 'twin.macro'
import {Grid, Cell} from 'styled-css-grid'
import {
  motion,
  useViewportScroll,
  useTransform,
  useElementScroll,
} from 'framer-motion'
import {useInView, InView} from 'react-intersection-observer'

import {scrollProgress} from '../hooks'

// markup
const IndexPage = () => {
  return (
    <Layout>
      <Intro></Intro>
      <Results></Results>
      <Marketing></Marketing>
    </Layout>
  )
}

export default IndexPage
