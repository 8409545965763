import React from 'react'
import GlobalStyles from '../styles/GlobalStyles'
import Header from './Header'
import Footer from './Footer'
import {LazyMotion, domAnimation} from 'framer-motion'
import {ModalProvider} from '../context/ModalContext'
import {Form, SEO} from '../components'
import tw from 'twin.macro'

export default ({children, ...rest}) => {
  return (
    <ModalProvider>
      <LazyMotion features={domAnimation}>
        <SEO />
        <div
          tw="font-body font-thin bg-light min-h-screen min-w-full"
          {...rest}
        >
          <GlobalStyles />
          <Form />
          <Header />
          {children}
          <Footer />
        </div>
      </LazyMotion>
    </ModalProvider>
  )
}
